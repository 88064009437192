import axios from "axios";
import authHeader from "~/repositories/auth-header";
import { API_URL } from "~/config/axios";

export const getAllSales = (
    { page, limit }: { page: number; limit: number},
    fromdate: any,
    todate:any,
    search: any,
  ) => {
    return axios.post(
      API_URL + "/SalesOrder/search",
      {
        pageSize: limit,
        pageNumber: page + 1,
        fromdate,
        todate,
        search,
      },
      { headers: authHeader() }
    );
  };
  
export const GetBillData = ({
  page,
  limit,
  custId,
}: {
  page: number;
  limit: number;
  custId: number;
}) => {
  return axios.post(
    API_URL + `/SalesOrder/search`,
    {
      pageSize: limit,
      pageNumber: page + 1,
      searchval1:custId.toString(),
    },
    { headers: authHeader() }
  );
};
  export const Create = (data: any) => {
    return axios.post(API_URL + "/SalesOrder/Create", data, {
      headers: authHeader(),
    });
  };
  
  export const Modify = (id: string, data: any) => {
    return axios.put(API_URL + `/SalesOrder/Update?salesorderid=${id}`, data, {
      headers: authHeader(),
    });
  };
  
  export const GetById = (id: string) => {
    return axios.get(API_URL + `/SalesOrder/${id}`, {
      headers: authHeader(),
    });
  };
  export const Delete = (id: number) => {
    return axios.delete(API_URL + `/SalesOrder/${id}`, {
      headers: authHeader(),
    });
  };
  export const CustomerList = (
    { page, limit }: { page: number; limit: number },
    search: any
  ) => {
    return axios.post(
      API_URL + "/Customer/get",
      {
        pageSize: limit,
        pageNumber: page + 1,
        search,
      },
      { headers: authHeader() }
    );
  };
  export const GetItemList = () => {
    return axios.post(
      API_URL + "/Dropdown/GetDDLListByType",
      {
        type: "Item-List-SO",
        branchId: 0,
        searchVal1: "",
        searchVal2: "",
        searchVal3: "",
        searchVal4: "",
        searchVal5: ""
      },
      {
        headers: authHeader(),
      }
    );
  };
  
  export const GetSalesItemList = (searchVal1 : string,catId : string) => {
    return axios.post(
      API_URL + "/Dropdown/GetDDLListByType",
      {
        type: "Item-List-SO",
        branchId: 0,
        searchVal1: searchVal1,
        searchVal2: catId,
        searchVal3: "",
        searchVal4: "",
        searchVal5: ""
      },
      {
        headers: authHeader(),
      }
    );
  };

  export const GetSalesAutoCompleteItemList = (id:string , searchVal1 : string) => {
    return axios.post(
      API_URL + "/Dropdown/GetItemListForSO",
      {
        salesorderid : id,
        itemName: searchVal1
      },
      {
        headers: authHeader(),
      }
    );
  };
  export const GetTableStructure =(categoryId : string) =>{
    return axios.get(API_URL + `/SalesOrder/GetSOItemHeader?categoryid=${categoryId}`,{
      headers :  authHeader(),
    });
  };

  export const GetPaymentModes = () => {
    return axios.post(
      API_URL + "/Dropdown/GetDDLListByType",
      {
        type: "Paymode-DDL",
        branchId: 0,
        searchVal1: "",
        searchVal2: "",
        searchVal3: "",
        searchVal4: "",
        searchVal5: ""
      },
      {
        headers: authHeader(),
      }
    );
  };

 export const GetAllAgents = () =>{
  return axios.post(
    API_URL + "/Dropdown/GetDDLListByType",
    {
      branchId: 0,
      type: "Agent-List",
      searchVal1: "",
      searchVal2: "",
      searchVal3: "",
      searchVal4: "",
      searchVal5: ""
    },
    {
      headers: authHeader(),
    }
  );
 };



  export const GetAllCustomers = (searchVal1: string) => {
    return axios.post(
      API_URL + "/Dropdown/GetDDLListByType",
      {
        type: "CustomerList-DDL",
        branchId: 0,
        searchVal1: searchVal1,
        searchVal2: "",
        searchVal3: "",
        searchVal4: "",
        searchVal5: ""
      },
      {
        headers: authHeader(),
      }
    );
  };